var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{attrs:{"name":"unity","rules":_vm.requiredField ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Unidade","label-for":"unity"}},[_c('v-select',{class:errors.length > 0 ? 'error-select' : '',attrs:{"id":"unity","filter":_vm.fuseSearch,"label":"symbol","options":_vm.dataOptions,"placeholder":_vm.readonly ? '' : 'Unidade',"disabled":_vm.readonly,"clearable":!_vm.readonly},on:{"open":function($event){return _vm.onCreate()}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var unity = ref.unity;
var symbol = ref.symbol;
return [_c('span',[_vm._v(_vm._s(unity)+" - "+_vm._s(symbol))])]}},{key:"selected-option",fn:function(ref){
var symbol = ref.symbol;
return [_c('span',[_vm._v(_vm._s(symbol)+" ")])]}},{key:"no-options",fn:function(){return [_vm._v(" Não há opções de unidades. ")]},proxy:true}],null,true),model:{value:(_vm.unitySelect),callback:function ($$v) {_vm.unitySelect=$$v},expression:"unitySelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }