<template>
  <div>
    <b-card no-body>
      <div v-if="isLoading">
        <b-row>
          <b-col>
            <div class="text-center my-3">
              <b-spinner
                variant="info"
                class="align-middle mb-1"
              />
              <br>
              <strong class="pt-2">Carregando...</strong>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <div
          v-if="imagesData.length === 0 && isViewMode"
          class="d-flex flex-wrap justify-content-center"
        >
          <b-row>
            <b-col
              class="d-flex justify-content-center align-self-center mt-3"
            >
              <p>Este produto não contém imagens cadastradas.</p>
            </b-col>
          </b-row>
        </div>
        <section
          v-show="!isLoading"
          class="grid-view m-1"
        >
          <b-row>
            <b-col
              v-for="(image, index) in imagesData"
              :key="image.id"
              sm="2"
              md="2"
              xl="1"
              class="mb-1"
            >
              <div
                class="image-wrap"
              >
                <span class="image-delete">
                  <b-button
                    v-if="!isViewMode"
                    :id="`btn_delete_${image.id}`"
                    variant="outline-info"
                    class="btn-icon rounded-circle"
                    size="sm"
                    @click="showConfirmOrCancelDelete(image)"
                  >
                    <svg-icon
                      type="mdi"
                      size="18"
                      :path="mdiTrashCanOutline"
                    />
                  </b-button>
                </span>
                <b-img
                  v-bind="mainProps"
                  :alt="`imagem-${image.id}`"
                  fluid
                  :src="image.image"
                  @click="openImgModal(index)"
                />
              </div>
            </b-col>
            <b-col
              v-if="!isViewMode"
              md="2"
              xl="1"
            >
              <div
                id="newPhotoBtn"
                class="d-flex justify-content-center mb-1 image-wrap"
              >
                <b-avatar
                  id="add_image_gallery_button"
                  class="image"
                  rounded
                  button
                  @click="$refs.fileInput.click()"
                >
                  <span>
                    <svg-icon
                      class="mr-10 icon-size"
                      type="mdi"
                      :path="mdiImagePlusOutline"
                    />
                  </span>
                </b-avatar>
                <input
                  id="add_image"
                  ref="fileInput"
                  type="file"
                  class="d-none"
                  accept="image/*"
                  @change="handleImage"
                >
              </div>
            </b-col>
          </b-row>
        </section>
      </div>
    </b-card>
    <div>
      <b-modal
        id="idModalCarousel"
        ref="refModalCarousel"
        centered
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        static
      >
        <b-carousel
          id="carousel"
          ref="refCarousel"
          class="mb-2"
          :interval="4000"
          controls
          indicators
          background="#ffffff"
          img-width="1024"
          img-height="480"
        >
          <b-carousel-slide
            v-for="image in imagesData"
            :key="image.id"
          >
            <template #img>
              <b-img
                class="d-block img-fluid w-100"
                width="1024"
                height="480"
                :alt="`imagem-${image.id}`"
                fluid
                :src="image.image"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BButton,
  BRow,
  BCol,
  BAvatar,
  BModal,
  BCarousel,
  BCarouselSlide,
  BSpinner,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiImagePlusOutline,
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import syslic from '@/syslic'

export default {
  name: 'ProductGalery',
  components: {
    BCard,
    BImg,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCarousel,
    BCarouselSlide,
    BSpinner,
    SvgIcon,
  },
  directives: {
    Ripple,
  },
  props: {
    isViewMode: {
      type: Boolean,
      required: true,
    },
    isCreateMode: {
      type: Boolean,
      required: true,
    },
    productId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    const id = this.productId
    const isLoading = true

    return {
      mainProps: {
        width: 100,
        height: 200,
        rounded: true,
        center: true,
        class: 'image',
      },
      imagesData: [],
      selectedFile: '',
      id,
      isLoading,
    }
  },
  created() {
    syslic
      .product
      .image
      .fetchImages(this.id)
      .then(response => {
        Object.values(response).forEach(image => {
          const newImage = {
            id: image.id,
            image: `data:image/jpg;base64,${image.image}`,
          }
          this.imagesData.push(newImage)
        })
        this.$emit('ready', true)
        this.isLoading = false
      })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao ler os dados.',
            text: 'Não foi possível carregar as imagens, por favor entre em contato com o administrador do sistema.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            timeout: false,
          },
        })
        this.isLoading = false
        this.$emit('ready', true)
      })
  },
  methods: {
    showConfirmOrCancelDelete(image) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover a foto.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteImage(image.id)
          }
        })
    },
    handleImage(event) {
      let selectedFile = event.target.files[0]
      if (selectedFile.size > 15000000) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao adicionar foto.',
            text:
              'A foto deve ter tamanho de até 15MB',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            timeout: false,
          },
        })
        selectedFile = ''
        return
      }
      const allowedExtensions = /(jpg|jpeg|png|gif)/i
      if (!allowedExtensions.exec(selectedFile.type)) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Falha ao adicionar foto.',
            text:
              'A foto deve ter o formato JPG, GIF ou PNG, com tamanho de até 15MB',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            timeout: false,
          },
        })
        selectedFile = ''
        return
      }
      const reader = new FileReader()
      reader.onload = () => {
        this.selectedFile = reader.result
      }
      reader.onloadend = () => {
        this.setImage()
      }
      reader.readAsDataURL(selectedFile)
    },
    setImage() {
      this.isLoading = true
      syslic
        .product
        .image
        .setImage(this.id, this.selectedFile)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Imagem Adicionada.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          return syslic.product.image.fetchImages(this.id)
        })
        .then(response => {
          this.imagesData = []
          Object.values(response).forEach(image => {
            const newImage = {
              id: image.id,
              image: `data:image/jpg;base64,${image.image}`,
            }
            this.imagesData.push(newImage)
          })
          this.selectedFile = ''
          this.isLoading = false
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error ao adicionar foto.',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          this.isLoading = false
        })
    },
    deleteImage(id) {
      this.isLoading = true
      syslic
        .product
        .image
        .deleteImage(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Imagem Removida.',
              icon: 'Trash2Icon',
              variant: 'success',
            },
          })
          return syslic.product.image.fetchImages(this.id)
        })
        .then(response => {
          this.imagesData = []
          Object.values(response).forEach(image => {
            const newImage = {
              id: image.id,
              image: `data:image/jpg;base64,${image.image}`,
            }
            this.imagesData.push(newImage)
          })
          this.isLoading = false
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error ao remover foto.',
              icon: 'Trash2Icon',
              variant: 'danger',
            },
          })
          this.isLoading = false
        })
    },
    openImgModal(index) {
      this.$refs.refModalCarousel.show()
      this.$refs.refCarousel.setSlide(index)
    },
  },
  setup() {
    const toast = useToast()
    return {
      toast,
      mdiImagePlusOutline,
      mdiTrashCanOutline,
    }
  },
}

</script>

<style>
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 1rem;
    max-width: 80rem;
    margin: 5rem auto;
    padding: 0 5rem;
  }

.image {
  width: 100%;
  object-fit: cover;
  border-radius: 1.1rem;
}

.image-wrap {
  position: relative;
}

.image-wrap .image-delete {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
}

.image-wrap .btn{
  background-color: #ffffff;
}

.image-wrap .btn:hover {
  opacity: 0.5;
  transition-delay:0s;
  background-color: #ffffff !important;
}

.image-wrap .help-text-image {
  position: absolute;
  z-index: 10;
  color: #6e6b7b;
  display: inline-block;
  bottom: 0;
  margin-bottom: 15%;
  font-size: large;
}

.icon-size {
  height: 2rem;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 5%;
}

#add_image_gallery_button {
  background-color: #F8F8F8 !important;
  /* border: 2px dashed #464646 !important; */
  color: #6e6b7b !important;
}

#newPhotoBtn {
  height: 100%;
}

#newPhotoBtn .btn {
  height: 100%;
}

</style>
