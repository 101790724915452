<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
        >
          <div
            v-if="hasPermissionToRead"
          >
            <div
              v-if="hasPermissionToUpdate"
            >
              <b-row
                v-if="isViewMode"
              >
                <b-col
                  class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                >
                  <b-dropdown
                    id="actions"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    right
                    class="dropdown-icon-wrapper"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="SettingsIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>

                    <b-dropdown-item
                      id="bnt-edit"
                      :disabled="!hasPermissionToUpdate"
                      @click="enableEdition(prodId)"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="!hasPermissionToDelete"
                      @click="showConfirmOrCancelDelete(prodId)"
                    >
                      <svg-icon
                        type="mdi"
                        size="16"
                        :path="mdiTrashCanOutline"
                      />
                      <span class="align-middle ml-50">Remover</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-row>
                <b-col>
                  <validation-observer
                    #default="{ handleSubmit, invalid }"
                    ref="refFormObserver"
                  >
                    <b-form
                      ref="itemForm"
                      class="mt-1"
                      @submit.prevent="handleSubmit(onSubmit)"
                    >
                      <b-card
                        class="mb-2"
                        no-body
                      >
                        <b-card-title
                          class="mt-2 ml-2 mb-0 cardHeader"
                        >
                          <feather-icon
                            icon="InfoIcon"
                            size="23"
                            class="mr-50"
                          />
                          <span class="d-inline">Cadastro de Produto</span>
                        </b-card-title>
                        <hr>
                        <b-card-body>
                          <b-row>
                            <b-col
                              cols="12"
                              md="12"
                              lg="6"
                            >
                              <b-row>
                                <b-col>
                                  <validation-provider
                                    #default="validationContext"
                                    ref="itemName"
                                    name="name"
                                    rules="required"
                                  >
                                    <b-form-group
                                      label="Nome"
                                      label-for="name"
                                    >
                                      <b-form-input
                                        id="name"
                                        v-model="itemData.name"
                                        :state="isViewMode ? null : getValidationState(validationContext)"
                                        placeholder="Nome"
                                        :readonly="isViewMode"
                                      />

                                      <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col>
                                  <validation-provider
                                    #default="validationContext"
                                    name="description"
                                  >
                                    <b-form-group
                                      label="Descrição"
                                      label-for="description"
                                    >
                                      <b-form-textarea
                                        id="itemDescription"
                                        v-model="itemData.description"
                                        :state="isViewMode ? null : getValidationState(validationContext)"
                                        trim
                                        :placeholder="isViewMode ? '' : 'Descrição'"
                                        :readonly="isViewMode"
                                      />

                                      <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="6"
                                >
                                  <classifier-select
                                    id="classifier"
                                    v-model="itemData.classifier_set"
                                    :readonly="isViewMode"
                                    :required-field="false"
                                    @ready="syncLoad.ready('classifier')"
                                  />
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="6"
                                >
                                  <type-select
                                    id="type"
                                    v-model="itemData.type_set"
                                    :readonly="isViewMode"
                                    :required-field="false"
                                    @ready="syncLoad.ready('type')"
                                  />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="3"
                                  lg="3"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="height"
                                  >
                                    <b-form-group
                                      label="Altura"
                                      label-for="height"
                                    >
                                      <b-input-group
                                        append="m"
                                        :class="[errors.length > 0 ? 'input-group-merge is-invalid' : 'input-group-merge', isViewMode ? 'disabledEdition input-readonly' : '']"
                                      >
                                        <cleave
                                          id="height"
                                          v-model="itemData.height"
                                          class="form-control"
                                          :options="options.size"
                                          :placeholder="isViewMode ? '' : 'Altura'"
                                          :readonly="isViewMode"
                                          @blur="adjustDecimalPlaces('height', 2)"
                                        />
                                      </b-input-group>

                                      <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="3"
                                  lg="3"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="width"
                                  >
                                    <b-form-group
                                      label="Largura"
                                      label-for="width"
                                    >
                                      <b-input-group
                                        append="m"
                                        :class="[errors.length > 0 ? 'input-group-merge is-invalid' : 'input-group-merge', isViewMode ? 'disabledEdition input-readonly' : '']"
                                      >
                                        <cleave
                                          id="width"
                                          v-model="itemData.width"
                                          class="form-control"
                                          :options="options.size"
                                          :placeholder="isViewMode ? '' : 'Largura'"
                                          :readonly="isViewMode"
                                          @blur="adjustDecimalPlaces('width', 2)"
                                        />
                                      </b-input-group>

                                      <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="3"
                                  lg="3"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="lenght"
                                  >
                                    <b-form-group
                                      label="Profundidade"
                                      label-for="lenght"
                                    >
                                      <b-input-group
                                        append="m"
                                        :class="[errors.length > 0 ? 'input-group-merge is-invalid' : 'input-group-merge', isViewMode ? 'disabledEdition input-readonly' : '']"
                                      >
                                        <cleave
                                          id="lenght"
                                          v-model="itemData.lenght"
                                          class="form-control"
                                          :options="options.size"
                                          :placeholder="isViewMode ? '' : 'Profundidade'"
                                          :readonly="isViewMode"
                                          @blur="adjustDecimalPlaces('lenght', 2)"
                                        />
                                      </b-input-group>
                                      <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="3"
                                  lg="3"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="weight"
                                  >
                                    <b-form-group
                                      label="Peso"
                                      label-for="weight"
                                    >
                                      <b-input-group
                                        append="kg"
                                        :class="[errors.length > 0 ? 'input-group-merge is-invalid' : 'input-group-merge', isViewMode ? 'disabledEdition input-readonly' : '']"
                                      >
                                        <cleave
                                          id="weight"
                                          v-model="itemData.weight"
                                          class="form-control"
                                          :options="options.weight"
                                          :placeholder="isViewMode ? '' : 'Peso'"
                                          :readonly="isViewMode"
                                          @blur="adjustDecimalPlaces('gross_weight', 3)"
                                        />
                                      </b-input-group>
                                      <b-form-invalid-feedback>
                                        {{ errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col
                              cols="12"
                              md="12"
                              lg="6"
                            >
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="4"
                                >
                                  <validation-provider
                                    #default="validationContext"
                                    name="brand"
                                  >
                                    <b-form-group
                                      label="Marca"
                                      label-for="brand"
                                    >
                                      <b-form-input
                                        id="brand"
                                        v-model="itemData.brand"
                                        :state="isViewMode ? null : getValidationState(validationContext)"
                                        trim
                                        :placeholder="isViewMode ? '' : 'Marca'"
                                        :readonly="isViewMode"
                                      />

                                      <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="4"
                                >
                                  <validation-provider
                                    #default="validationContext"
                                    name="model"
                                    rules="required"
                                  >
                                    <b-form-group
                                      label="Modelo"
                                      label-for="model"
                                    >
                                      <b-form-input
                                        id="model"
                                        v-model="itemData.model"
                                        :state="isViewMode ? null : getValidationState(validationContext)"
                                        trim
                                        :placeholder="isViewMode ? '' : 'Modelo'"
                                        :readonly="isViewMode"
                                      />

                                      <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="4"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="cost"
                                    rules="required"
                                  >
                                    <b-form-group
                                      label="Custo"
                                      label-for="cost"
                                    >
                                      <b-input-group
                                        prepend="R$"
                                        :class="[errors.length > 0 ? 'input-group-merge is-invalid' : 'input-group-merge', (isViewMode || itemData.has_material_list) ? 'disabledEdition input-readonly' : '']"
                                      >
                                        <cleave
                                          id="cost"
                                          v-model="itemData.price"
                                          name="price"
                                          :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                          :options="options.price"
                                          placeholder="0,00"
                                          :readonly="isViewMode"
                                          @blur="adjustDecimalPlaces('price', 2)"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="4"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="anvisa"
                                  >
                                    <b-form-group
                                      label="Nº Anvisa"
                                      label-for="anvisa"
                                    >
                                      <cleave
                                        id="anvisa"
                                        v-model="itemData.anvisa"
                                        name="anvisa"
                                        :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                        :options="options.number"
                                        :placeholder="isViewMode ? '' : 'Anvisa'"
                                        :readonly="isViewMode"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="4"
                                >
                                  <validation-provider
                                    #default="validationContext"
                                    ref="itemCode"
                                    name="code"
                                  >
                                    <b-form-group
                                      label="Código"
                                      label-for="code"
                                    >
                                      <b-form-input
                                        id="code"
                                        v-model="itemData.code"
                                        :state="isViewMode ? null : getValidationState(validationContext)"
                                        trim
                                        :placeholder="isViewMode ? '' : 'Código'"
                                        :readonly="isViewMode"
                                      />

                                      <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="12"
                                  md="4"
                                >
                                  <unit-select
                                    id="unit"
                                    v-model="itemData.unity_set"
                                    :readonly="isViewMode"
                                    :required-field="false"
                                    @ready="syncLoad.ready('unit')"
                                  />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="12"
                                >
                                  <warranty-select
                                    id="warranty"
                                    v-model="itemData.warranty_set"
                                    :readonly="isViewMode"
                                    :required-field="false"
                                    @ready="syncLoad.ready('warranty')"
                                  />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="12"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="date"
                                  >
                                    <b-form-group
                                      label="Data de Validade"
                                      label-for="date"
                                    >
                                      <b-form-datepicker
                                        id="date"
                                        v-model="itemData.expiration_date"
                                        :required="true"
                                        locale="pt-BR"
                                        :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        label-no-date-selected="Nenhuma data foi selecionada"
                                        :disabled="isViewMode"
                                        selected-variant="primary"
                                        :placeholder="isViewMode ? '' : 'Selecione uma data'"
                                        label-help="Use o teclado ou o mouse para navegar pelas datas do calendário"
                                        reset-button
                                        reset-button-variant="info"
                                        label-reset-button="Limpar"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="12"
                                >
                                  <supplier-select
                                    id="supplier"
                                    v-model="itemData.supplier_set"
                                    :readonly="isViewMode"
                                    :required-field="false"
                                    @ready="syncLoad.ready('supplier')"
                                  />
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col
                                  cols="12"
                                  md="12"
                                >
                                  <validation-provider
                                    #default="validationContext"
                                    name="link_supplier"
                                  >
                                    <b-form-group
                                      label="Link do Fornecedor"
                                      label-for="link_supplier"
                                    >
                                      <b-input-group>
                                        <b-input-group-prepend
                                          v-if="isViewMode"
                                          :class="{
                                            'disabledEdition' : isViewMode
                                          }"
                                          is-text
                                        >
                                          <b-link
                                            :href="(isViewMode && itemData.link_supplier !== '') ? getLink(itemData.link_supplier) : ''"
                                            target="_blank"
                                            rel="noreferrer"
                                            :disabled="!isViewMode"
                                          >
                                            <span>
                                              <svg-icon
                                                type="mdi"
                                                :path="mdiLinkBoxVariant"
                                              />
                                            </span>
                                          </b-link>
                                        </b-input-group-prepend>
                                        <b-form-input
                                          id="link_supplier"
                                          v-model="itemData.link_supplier"
                                          :state="isViewMode ? null : getValidationState(validationContext)"
                                          trim
                                          :placeholder="isViewMode ? '' : 'Link do Fornecedor'"
                                          :readonly="isViewMode"
                                        />
                                      </b-input-group>
                                      <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                      </b-form-invalid-feedback>
                                    </b-form-group>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-card>
                      <div class="d-inline-block w-100">
                        <b-card no-body>
                          <b-tabs
                            class="productTab"
                            card
                          >
                            <b-tab
                              :active="activeTab === dataTab.gallery"
                              @click="selectedTab(dataTab.gallery)"
                            >
                              <template #title>
                                <b-card-title
                                  class="mr-50 cardHeader"
                                >
                                  <feather-icon
                                    icon="ImageIcon"
                                    size="23"
                                    class="mr-50"
                                  />
                                  <span class="d-inline">Galeria de Imagens</span>
                                </b-card-title>
                              </template>
                              <b-card-body
                                class="p-0 mb-3"
                                no-body
                              >
                                <product-galery
                                  v-if="!isCreateMode"
                                  :is-view-mode="isViewMode"
                                  :is-create-mode="isCreateMode"
                                  :product-id="prodId"
                                />
                              </b-card-body>
                            </b-tab>
                            <b-tab
                              :active="activeTab === dataTab.attachment"
                              @click="selectedTab(dataTab.attachment)"
                            >
                              <template #title>
                                <b-card-title
                                  class="mr-50 cardHeader"
                                >
                                  <svg-icon
                                    type="mdi"
                                    :path="mdiPaperclip"
                                  />
                                  <span class="d-inline">Anexos</span>
                                </b-card-title>
                              </template>
                              <b-card-body
                                class="p-0"
                                no-body
                              >
                                <product-attachment
                                  v-model="itemData.file_set"
                                  :product-id="prodId"
                                  :is-view-mode="isViewMode"
                                  :is-create-mode="isCreateMode"
                                  @ready="syncLoad.ready('file')"
                                />
                              </b-card-body>
                            </b-tab>
                          </b-tabs>
                        </b-card>
                      </div>
                      <b-row
                        class="mt-2"
                      >
                        <b-col class="d-flex justify-content-end buttonsSaveCancel">
                          <b-button
                            v-if="!isViewMode"
                            id="btn_save_product"
                            type="submit"
                            variant="primary"
                            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            :disable="invalid"
                          >
                            Salvar
                          </b-button>
                          <b-button
                            id="btn_cancel_product"
                            variant="outline-primary"
                            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            :to=" { name: 'product' } "
                          >
                            Voltar
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTabs,
  BTab,
  BCardBody,
  BCardTitle,
  BForm,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
} from 'bootstrap-vue'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiLinkBoxVariant,
  mdiPaperclip,
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SupplierSelect from '@/components/supplier/SupplierSelect.vue'
import ClassifierSelect from '@/components/product/ClassifierSelect.vue'
import TypeSelect from '@/components/product/TypeSelect.vue'
import UnitSelect from '@/components/product/UnitSelect.vue'
import WarrantySelect from '@/components/product/WarrantySelect.vue'
import ProductGalery from './ProductGalery.vue'
import ProductAttachment from './ProductAttachment.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BCardBody,
    BCardTitle,
    BForm,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    Cleave,
    BFormInvalidFeedback,
    BLink,
    SvgIcon,
    ValidationObserver,
    ValidationProvider,
    ProductGalery,
    ProductAttachment,
    SpinLoader,
    SupplierSelect,
    ClassifierSelect,
    TypeSelect,
    UnitSelect,
    WarrantySelect,
  },
  directives: {
    Ripple,
  },
  data() {
    const itemData = {
      name: '',
      description: '',
      brand: '',
      model: '',
      price: '',
      anvisa: '',
      code: '',
      link_supplier: '',
      supplier: '',
      supplier_set: '',
      warranty: '',
      warranty_set: '',
      unity: '',
      unity_set: '',
      classifier: '',
      classifier_set: '',
      type: '',
      type_set: '',
      expiration_date: null,
      weight: '',
      lenght: '',
      width: '',
      height: '',
      get_price: '',
      has_material_list: '',
      file_set: [],
    }

    const dataTab = {
      gallery: 1,
      attachment: 2,
    }

    let activeTab = dataTab.gallery

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.itemData)

    let isViewMode = true
    let isCreateMode = false
    let isEditMode = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    }

    if (router.currentRoute.path.match(/.*\/edit\/.*/)) {
      isEditMode = true
    }

    let prodId = ''
    if (isViewMode || isEditMode) {
      prodId = router.currentRoute.params.id
      activeTab = router.currentRoute.params.tab
    }

    const isLoaded = false
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('item')
    syncLoad.addTrigger('classifier')
    syncLoad.addTrigger('type')
    syncLoad.addTrigger('unit')
    syncLoad.addTrigger('warranty')
    syncLoad.addTrigger('file')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      itemData,
      prodId,
      isViewMode,
      isCreateMode,
      isEditMode,
      refFormObserver,
      getValidationState,
      dataTab,
      activeTab,
      required,
      options: {
        weight: {
          numeral: true,
          numeralDecimalScale: 3,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
        size: {
          numeral: true,
          numeralDecimalScale: 2,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
        price: {
          numeral: true,
          numeralDecimalScale: 2,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
        number: {
          numeral: true,
          numeralDecimalScale: 0,
          numeralDecimalMark: '',
          delimiter: '',
        },
      },
      colorError: '#ea5455',
      isLoaded,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.product.can_edit
    },
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.product.can_read
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.product.can_delete
    },
    price: {
      get() {
        return this.itemData.has_material_list ? this.itemData.get_price.toFixed(2) : this.itemData.price
      },
      set(value) {
        this.itemData.price = value
      },
    },
  },
  watch: {
    'itemData.warranty_set': function updateWarranty(obj) {
      if (obj) {
        this.itemData.warranty = obj.id
      }
    },
    'itemData.unity_set': function updateUnity(obj) {
      if (obj) {
        this.itemData.unity = obj.id
      }
    },
    'itemData.classifier_set': function updateClassifier(obj) {
      if (obj) {
        this.itemData.classifier = obj.id
      }
    },
    'itemData.type_set': function updateType(obj) {
      if (obj) {
        this.itemData.type = obj.id
      }
    },
    'itemData.supplier_set': function updateSupplier(obj) {
      if (obj) {
        this.itemData.supplier = obj.id
      }
    },
    'itemData.expiration_date': function updateExpirationDate(obj) {
      if (obj === '') {
        this.itemData.expiration_date = null
      }
    },
  },
  created() {
    if (this.isViewMode || this.isEditMode) {
      syslic
        .product
        .item
        .fetchItem(this.prodId)
        .then(response => {
          this.itemData = response.data
          this.syncLoad.ready('item')
        })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao carregar o produto.',
              text: 'Não foi possivel carregar o produto do servidor, por favor entre em contato com o administrador do sistema',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })

          if (error.status === 404) {
            router.push({ name: 'error-404' })
          }
          this.syncLoad.ready('item')
        })
    } else {
      this.syncLoad.ready('item')
    }
  },
  methods: {
    adjustDecimalPlaces(field, places) {
      const decimalReference = 10 * places
      const num = this.itemData[field]

      if (num !== '') {
        this.itemData[field] = ((num * decimalReference) / decimalReference).toFixed(places)
      }
    },
    createItem() {
      syslic
        .product
        .item
        .addItem(this.itemData)
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Item adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.itemData = response
          this.disableEdition(response.id)
        })
        .catch(error => {
          let textError = 'Não foi possível adicionar o produto, por favor entre em contato com o administrador do sistema.'

          if (error.data.includes('duplicate key value violates unique constraint')) {
            textError = 'Não foi possível adicionar este produto pois já existe um produto cadastrado com o mesmo nome ou modelo.'
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar produto.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updatedItem() {
      syslic
        .product
        .item
        .updateItem(this.prodId, this.itemData)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Item atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition(this.prodId)
        })
        .catch(error => {
          let textError = 'Não foi possível atualizar o produto, por favor entre em contato com o administrador do sistema.'

          if (error.data.includes('duplicate key value violates unique constraint')) {
            textError = 'Não foi possível atualizar este produto pois já existe um produto cadastrado com o mesmo nome ou modelo.'
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o produto.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    deleteItem(item) {
      syslic
        .product
        .item
        .deleteItem(item)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Produto removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'product' })
        })
        .catch(error => {
          let textError = 'Não foi possível remover o produto do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Esta unidade é referenciada em algum produto, por este motivo não será possível remove-la.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover produto.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    selectedTab(value) {
      this.activeTab = value
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.createItem()
      } else {
        this.updatedItem()
      }
    },
    enableEdition(id) {
      router.push({ name: 'product-item-edit', params: { id, tab: this.activeTab } }).catch(() => {})
    },
    disableEdition(id) {
      router.push({ name: 'product-item-detail', params: { id, tab: this.activeTab } }).catch(() => {})
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover o produto.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteItem(item)
          }
        })
    },
    getLink(url) {
      let path = url
      if (!((url.includes('http://')) || (url.includes('https://')))) {
        path = `https://${path}`
      }
      return path
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiLinkBoxVariant,
      mdiPaperclip,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.error-select > div:first-child {
  border-color: #ea5455;
}

.disabledEdition .input-group-text{
  background-color: #efefef;
}

#itemDescription {
  min-height: 13.2rem;
}

.disabledEdition .input-group-text{
  background-color: #EAEEF5;
  border: 0;
}

.productTab .card-header {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.productTab .nav-link {
  padding-left: 2rem;
  padding-right: 2rem;
}

.productTab .nav-link h4 {
  color: #6e6b7b;
  font-size: 1rem;
}

.productTab .nav-link.active {
  background-color: #e2edf3;
}

.productTab .nav-link.active h4 {
  color: $primary;
}

.productTab .nav-link:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.productTab .tab-pane.card-body {
  padding: 1rem;
}

.productTab .tab-content {
  margin-top: -2rem !important;
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
