<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="type"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Tipo"
        label-for="type"
      >
        <v-select
          id="type"
          v-model="type"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="name"
          :options="dataOptions"
          :placeholder="readonly ? '' : 'Tipo'"
          :disabled="readonly"
          :clearable="!readonly"
          @open="onCreate()"
        >
          <template #option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #selected-option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #no-options>
            Não há opções de tipos.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'typeValue',
    event: 'typeChange',
  },
  props: {
    typeValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const dataOptions = []

    return {
      dataOptions,
      required,
    }
  },
  computed: {
    type: {
      get() {
        return this.typeValue
      },
      set(value) {
        this.$emit('typeChange', value)
      },
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .product
        .type
        .fetchTypes()
        .then(response => {
          this.dataOptions = response.results
          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
